html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  background-color: gray;
  overflow: hidden;
}

.app {
  background-color: black;
  width: 100%;
  height: 100%;
}
